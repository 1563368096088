export default class BookingsRecommendationsController {
  constructor($scope) {
    this.scope = $scope;
    this.lodash = _;

    this._resetState();
    this._initWatchers();
  }

  /**
   *
   */
  triggerRecommendationInfo($index) {
    let state = _.clone(this.state);

    if (!_.isNull(state.openRecommendationForm)) {
      state.openRecommendationForm = null;
    }

    if (state.openRecommendationInfo === $index) {
      state.openRecommendationInfo = null;
    } else {
      state.openRecommendationInfo = $index;
    }

    this._setState(state);
  }

  /**
   *
   */
  triggerRecommendationForm($index) {
    let state = _.clone(this.state);

    if (!_.isNull(state.openRecommendationInfo)) {
      state.openRecommendationInfo = null;
    }

    if (state.openRecommendationForm === $index) {
      state.openRecommendationForm = null;
    } else {
      state.openRecommendationForm = $index;
    }

    this._setState(state);
  }

  removeRecommendation($index) {
    const currentRecommendationData = _.clone(this.state.recommendationData);

    currentRecommendationData.splice($index, 1);

    this._setState({
      ...this.state,
      recommendationData: currentRecommendationData
    });
  }

  addRecommendation() {
    const currentRecommendationData = _.clone(this.state.recommendationData);

    currentRecommendationData.push({
      contact: {},
      rsvp: {}
    });

    this._setState({
      ...this.state,
      recommendationData: currentRecommendationData
    });

    this.triggerRecommendationForm(this.state.recommendationData.length - 1);
  }

  handleRecommendationDataUpdate($index, data) {
    const currentState = this.state;

    _.set(currentState, 'recommendationData.' + $index, data);

    this._setState(currentState);

    this._triggerUpdatedDataEvent();
  }

  /**
   *
   * @private
   */
  _triggerUpdatedDataEvent() {
    const newData = {
      data: this._deFlatFormData()
    };

    this.onDataChange(newData);
  }

  /**
   *
   * @private
   */
  _deFlatFormData() {
    return _.cloneDeep(this.state.recommendationData);
  }

  /**
   * Init necessary watchers on the scope
   * @private
   */
  _initWatchers() {
    this.scope.$watch(() => {
      return this.recommendationsData;
    }, (newVal, oldVal) => {
      if (this.recommendationsData) {
        this._setState({
          ...this.state,
          recommendationData: this.recommendationsData
        });
      }
    });

    this.scope.$watch(() => {
      return this.eventInformation;
    }, () => {
      if (this.eventInformation) {
        this._setState({
          ...this.state,
          eventInformation: this.eventInformation
        });
      }
    });
  }

  /**
   *
   * @param state
   * @private
   */
  _setState(state) {
    this.state = state;
    this.scope.$applyAsync();
  }

  /**
   *
   * @private
   */
  _resetState() {
    this._setState({
      eventInformation: {},
      recommendationData: [],
      openRecommendationInfo: null,
      openRecommendationForm: null
    });
  }
}

BookingsRecommendationsController.$inject = [
  '$scope'
];