import '../styles/index.scss';

import angular from 'angular';
window.angular = angular;

import _ from 'lodash';
window._ = _;

import moment from 'moment';
window.moment = moment;

import swal from 'sweetalert';
window.swal = swal;

import 'angular-scroll';

// import 'moment-timezone';

import './vendor/airlst-sdk_1.1.1';
import './vendor/angular-locale_de-de';

import RegistrationController from './controllers/registration-controller';
import formFieldsComponent from './components/form-fields/component';
import formOverviewComponent from './components/form-overview/component';
import bookingsGuestsComponent from './components/guests/component';
import bookingsRecommendationsComponent from './components/recommendations/component';
require('angular-moment-picker');

angular.module('al-landingpage', [
    'AirLST-SDK',
    'moment-picker',
    'duScroll'
])
  .config([
    '$locationProvider',
    ($locationProvider) => {
      $locationProvider
        .html5Mode(false)
        .hashPrefix('!');
    }
  ])
  .config(['momentPickerProvider', function (momentPickerProvider) {
    momentPickerProvider.options({
      /* Picker properties */
      locale: 'de',
      format: 'L LTS',
      minView: 'decade',
      maxView: 'minute',
      startView: 'year',
      autoclose: true,
      today: false,
      keyboard: false,

      /* Extra: Views properties */
      leftArrow: '&larr;',
      rightArrow: '&rarr;',
      yearsFormat: 'YYYY',
      monthsFormat: 'MMM',
      daysFormat: 'D',
      hoursFormat: 'HH:[00]',
      minutesFormat: moment.localeData().longDateFormat('LT').replace(/[aA]/, ''),
      secondsFormat: 'ss',
      minutesStep: 5,
      secondsStep: 1
    });
  }])
  .controller('RegistrationController', RegistrationController)
  .component('formFields', formFieldsComponent)
  .component('bookingGuests', bookingsGuestsComponent)
  .component('bookingRecommendations', bookingsRecommendationsComponent)
  .component('formOverview', formOverviewComponent);
