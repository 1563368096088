export default class BookingsGuestsController {
  constructor($scope) {
    this.scope = $scope;
    this.lodash = _;

    this._resetState();
    this._initWatchers();
  }

  /**
   *
   */
  triggerGuestInfo($index) {
    let state = _.clone(this.state);

    if (!_.isNull(state.openGuestForm)) {
      state.openGuestForm = null;
    }

    if (state.openGuestInfo === $index) {
      state.openGuestInfo = null;
    } else {
      state.openGuestInfo = $index;
    }

    this._setState(state);
  }

  /**
   *
   */
  triggerGuestForm($index) {
    let state = _.clone(this.state);

    if (!_.isNull(state.openGuestInfo)) {
      state.openGuestInfo = null;
    }

    if (state.openGuestForm === $index) {
      state.openGuestForm = null;
    } else {
      state.openGuestForm = $index;
    }

    this._setState(state);
  }

  removeGuest($index) {
    const currentGuestData = _.clone(this.state.guestData);

    currentGuestData.splice($index, 1);

    this._setState({
      ...this.state,
      guestData: currentGuestData
    });

    this._triggerUpdatedDataEvent();
  }

  addGuest() {
    const currentGuestData = _.clone(this.state.guestData);

    currentGuestData.push({
      contact: {},
      rsvp: {}
    });

    this._setState({
      ...this.state,
      guestData: currentGuestData
    });

    this.triggerGuestForm(this.state.guestData.length - 1);
    this._triggerUpdatedDataEvent();
  }

  handleGuestDataUpdate($index, data) {
    const currentState = this.state;

    _.set(currentState, 'guestData.' + $index, data);

    this._setState(currentState);

    this._triggerUpdatedDataEvent();
  }

  /**
   *
   * @private
   */
  _triggerUpdatedDataEvent() {
    const newData = {
      data: this._deFlatFormData()
    };

    this.onDataChange(newData);
  }

  /**
   *
   * @private
   */
  _deFlatFormData() {
    return _.cloneDeep(this.state.guestData);
  }

  /**
   * Init necessary watchers on the scope
   * @private
   */
  _initWatchers() {

    this.scope.$watch(() => {
      return this.guestsData;
    }, () => {
      if (this.guestsData) {
        this._setState({
          ...this.state,
          guestData: this.guestsData
        });
      }
    });

    this.scope.$watch(() => {
      return this.eventInformation;
    }, () => {
      if (this.eventInformation) {
        this._setState({
          ...this.state,
          eventInformation: this.eventInformation
        });
      }
    });

    this.scope.$watch(() => {
      return this.minGuestCount;
    }, () => {
      this._setState({
        ...this.state,
        minGuestCount: this.minGuestCount
      });
    });

    this.scope.$watch(() => {
      return this.maxGuestCount;
    }, () => {
      console.log(this.maxGuestCount);
      this._setState({
        ...this.state,
        maxGuestCount: this.maxGuestCount
      });
    });

    this.scope.$watch(() => {
      return this.rsvp;
    }, () => {
      if (this.rsvp) {
        this._setState({
          ...this.state,
          rsvp: this.rsvp
        });

        if(this.state.rsvp.pax_max > 0) {
          this._setState({
            ...this.state,
            maxGuestCount: this.state.rsvp.pax_max
          });
        }

      }
    });

    this.scope.$watch(() => {
      return this.guestFields;
    }, () => {
      this._setState({
        ...this.state,
        guestFields: this.guestFields
      });
    });
  }

  /**
   *
   * @param state
   * @private
   */
  _setState(state) {
    this.state = state;
    this.scope.$applyAsync();
  }

  /**
   *
   * @private
   */
  _resetState() {
    this._setState({
      guestData: [],
      openGuestInfo: null,
      openGuestForm: null,
      minGuestCount: 0,
      maxGuestCount: 0,
      guestFields: {}
    });
  }

}

BookingsGuestsController.$inject = [
  '$scope'
];
