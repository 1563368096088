import template from './component.html';
import FormFieldsController from './controller';

export default {
  template: template,
  controller: FormFieldsController,
  controllerAs: 'vm',
  bindings: {
    formData: '<',
    formConfig: '<',
    onDataChange: '&'
  }
};